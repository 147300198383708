.container {
  align-content: left;
}
canvas {
  z-index: 2rem;
//   width: 700px;
//   height: 900px;
  margin-top: 3rem;
  margin-left: 15rem;
  margin-right: 5rem;
  border-radius: 1rem;
  border: 1px solid darkgray;
}
