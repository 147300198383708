@import url("https://fonts.googleapis.com/css2?family=Inconsolata:wght@700&display=swap");

$headings-font-family: "Inconsolata", monospace;
$headings-font-weight: 700;
$primaryColor: #393f4d;
$secondaryColor: black;
$primaryTextColor: #fff;
$textColor: #c5bec4;
$secondaryTextColor: #eece1a;
$formBackgroundColor: #d4d4dc;
$myImage: url(https://pbs.twimg.com/profile_images/541675965840367617/r4CIPwnj.jpeg);


//media Query Mixins
@mixin mediaSm {
  @media screen and (max-width: 600px) {
    @content;
  }
}
@mixin mediaMd {
  @media screen and (max-width: 768px) {
    @content;
  }
}
@mixin mediaLg {
  @media screen and (min-width: 769px) and (max-width: 1170px) {
    @content;
  }
}
@mixin mediaXL {
  @media screen and (min-width: 1171px) {
    @content;
  }
}
