@import "~bootstrap/scss/bootstrap.scss";
@import "../../styles/global.scss";

.navbar {
  // position: fixed;
  display: relative;
  justify-content: flex-end;
  min-height: 3rem;
  width: 100%;
  // background-color: $primaryColor;
  color: $secondaryTextColor;
  align-items: flex-end;
  padding-top: 2rem;

  a {
    margin-bottom: 0em;
    color: $primaryTextColor;
    &:hover {
      color: $secondaryTextColor;
    }
    &:active {
      color: $secondaryTextColor
    }
  }
}
