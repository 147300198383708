// @import "~bootstrap/scss/bootstrap";
@import "./styles/global.scss";

.App {
  text-align: center;
}
.main-container {
  width: 100%;
}
.header {
  background: linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)),url("/images/Web-Development.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  min-height: 30vh;
  background-color: $primaryColor;
  align-content: flex-end;
  width: 100%;
  border-bottom: 3px solid $secondaryTextColor;
  box-sizing: border-box;
}
.me-name {
  text-align: left;
  color: $primaryTextColor;
  margin-left:4rem;

}
h5 {
  color: $primaryTextColor;
  text-align: left;
  padding-bottom: 2rem;
  margin-left:4rem
}

.footer {
  border-top: 4px solid $secondaryTextColor;
  padding-top: 2rem;
  background-color: $primaryColor;
  color: $textColor;
  text-align: center;
  width: 100%;
  min-height: 5rem;
}
