@import "../../styles/global.scss";

.home-container {
  padding-top: 0rem;
  text-align: center;
  min-height: 100vh;
  margin: auto;
  background-color: $textColor;
}
.About-intro {
  padding-left: 2rem;
  text-align: left;
  text-transform: uppercase;
  box-sizing: border-box;
  padding-top: 0.5rem;
}

.profile-wrap {
  display: flex;
  margin-top: -4rem;
  flex-direction: row;
  justify-content: space-between;
  padding: 3rem;
  padding-top: 0rem;
  background-color: $textColor;
  .Image {
    float: left;
    border-radius: 50%;
    height: 30rem;
    width: 30rem;
    padding: 5rem;
  }
}

#connect-wrap {

  margin-top: 2rem;
  padding: 2rem;
  background-color: $primaryColor;
  border: 1px solid $secondaryTextColor;
  color: $primaryTextColor;
  border-radius: 3rem;
  justify-content: center;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
  z-index: 1;
  a {
    color: $secondaryColor;
    &:hover {
      color: $secondaryTextColor;
    }
  }
  .gitLink {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
.skills-wrap .skills {
  border: white 1px solid;
  border-radius: 10px;
  padding: 10px 5px;
  box-shadow: 0 0 5px rgba(255, 255, 255, 1.0);
}
  .skills-wrap span {
    border-radius: 5px;
    margin: 8px 12px;
    color: $primaryColor;
    padding: 5px;
    display: inline-block;
    &:hover {
      background-color: $formBackgroundColor;
    }
  }
  .logos{
    height: 4rem;
    width: auto;
  }
}
