@import "../../styles/global.scss";

.projects-container {
  background: url("/images/Web-Development.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  height: 100vh;
  width: 100%;
  background-color: $primaryColor;
  border-top: 2px solid $secondaryTextColor;
  border-bottom: 2px solid $secondaryTextColor;
  margin: 0;
  .projects-wrapper {
    padding: 2rem;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    justify-content: space-between;
    align-items: center;
    
    .flip-card {
      background: transparent;
      // width: 30rem;
      height: 20rem;
      margin: 2rem
    }
    .flip-card-inner{
      position: relative;
      width: 100%;
      height: 100%;
      text-align: center;
      transition: transform 0.8s;
      transform-style: preserve-3d;
      padding: 1rem
      
      // border: 1px solid $secondaryTextColor;
      
    }
    .flip-card:hover .flip-card-inner {
      transform: rotateY(180deg);
    }
    .card-front, .card-back{
      position: absolute;
      width: 100%;
      height: 100%;
      -webkit-backface-visibility: hidden; /* Safari */
      backface-visibility: hidden;
      background-color: $primaryColor;
      border-radius: 30px;
      box-shadow: 0 0 20px 10px rgba(0, 0, 0, 0.3);
      
      
      h4 {
        padding: 1rem;
      }
    }

    .card-front{
      background-color: $primaryColor;
      color: $primaryTextColor
    }
    .card-back{
      background-color: $primaryTextColor;
      color: $primaryColor;
      transform: rotateY(180deg);
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.8);
      text-align: center;
      padding: 0.5rem
      
      
    }
    .project-img {
      width: 100%;
      height: 80%;
      border-radius: 30px;
      
      
    }
    .p {
      padding-top: 2rem;
    }
  }
}




h3 {
  padding-left: 2rem;
  padding-top: 2rem;
}
