
// Widescreens
@include mediaXL {
}
//Desctops & laptops
@include mediaLg {
  .contact-wrap Form{
    width: auto;
  }
}
// tablets & small Laptops
@include mediaMd {
  body {
    align-items: center;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    overflow-x: hidden;
  .main-container{
    overflow-x: hidden
  }

    .home-container {
      text-align: center;
      align-items: center;
      &.h4 {
        text-align: center;
      }

      .me-name {
        margin-top: 0;
        padding-left: 0;
      }
    }
    .profile-wrap {
      flex-direction: column;
      margin-top: auto;
      justify-content: center;
      align-items: center;

      .Image {
        height: 20rem;
        width: 20rem;
        padding: 2rem;
      }
      .connect-wrap {
        float: none;
        margin-top: 0;
        padding: 2rem;
        width: 100%;
        min-height: 0;
      }
    }
    .projects-container{
align-items: center;
      height: 90rem;
    .projects-wrapper {
        display: grid;
        grid-template-columns: 1fr;
        // grid-row:repeat(3, 1fr);
        padding-top: 1rem;
        .flip-card{
          padding-top: 1rem
        }
      }
      
    }
    .contact-wrap {
      align-items: center;
      h4 {
        text-align: start;
      }
      .wrap {
        width: auto;
        align-content: flex-start;
        padding-left: 2rem;
      }
      form {
        width: auto;
      }
      .icons-wrap{
        left: 0
      }
    }
    .container {
      align-content: left;
      canvas {
        z-index: 2rem;
        margin-top: 3rem;
        margin-left: 0;
        border-radius: 1rem;
        border: 1px solid darkgray;
      }
    }
    .success-message{
      width: auto;
      height: auto;
      margin: 2rem;
      align-self: center;
      padding: 1rem;

    }
  }

  // Smartphones
  @include mediaSm {
    body {
      align-items: center;
      text-align: center;
      .header {
        .navbar {
          padding-top: 1rem;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
        }
        .menu-btn{
          display: block;
        }
        .me-name {
          padding-top: 2rem;
          text-align: center;
        }
      .title-text {
        text-align: center;
      }
      }
      .home-container{
        padding: 0;
      }
      .profile-wrap{
        padding: 0;
        .Image{
          margin-left: 2rem
        }
        #connect-wrap {
          margin: 2rem;
        }
      }
      .contact-wrap{
        min-height: 40rem;
        align-items: center;
        Form {
          padding-right: 2rem;
          width: auto
        }
        .icons-container {
          align-items: center;

          .icons-wrap{
            display: flex;
            flex-direction: row;
            position: relative;
            top: 0;
            margin: auto;
            background: none;
            border: none;
            box-shadow: none;
            .link {
              color: $primaryColor
            }
            .link:hover {
              color:$secondaryTextColor
            }
          }
        }
      }
      .contact-wrap{
        .wrap{
          display: flex;
          flex-direction: column;
        }
      }
    }
  }
}
