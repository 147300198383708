@import "../../styles/global.scss";

.contact-wrap {
  display: flex;
  flex-direction: column;
  min-height: 35rem;
  background-color: $textColor;


  .wrap {
    padding-left: 7rem;
    padding-top: 2rem;
    text-align: left;
  }
  Form {
    padding-top: 3rem;
    padding-left: 6rem;
    background-color: $formBackgroundColor;
    margin-top: 2rem;
    max-width: 60rem;
    margin-bottom: 2rem;
    padding-bottom: 2rem;
    box-sizing: border-box;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);

    Button {
      background-color: $primaryColor;
      border: $primaryColor;
    }
  }

  .icons-container {
    text-align: center;
    h4 {
      padding: 2rem;
      color: $primaryColor;
    }

    .icons-wrap {
      display: flex;
      flex-direction: column;
      position: fixed;
      top: 20rem;
      background-color: $secondaryTextColor;
      color: $primaryTextColor;
      border-radius: 2rem;
      z-index: 2;
      box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.3);
    }
  }
}
.linkedin {
  width: 3rem;
  height: 3rem;
  margin: 1rem;
}
.github {
  width: 3rem;
  height: 3rem;
  margin: 1rem;
}
.email {
  width: 3rem;
  height: 3rem;
  margin: 1rem;
}
.link {
  color: $primaryTextColor;

  &:hover {
    color: $primaryColor;
  }
}
.success-message{
  height: 330px;
  max-width: 60rem;
  text-align: center;
  padding: 5rem;
  margin: 2rem 30%;
  background: #f2f2f2;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.3);
}
